html {
    scroll-behavior: smooth;
}

body{
    background-color:rgba(0, 0, 0, 1); 
}

#home {
    min-height:100vh;
    padding-top:250px;
    width:100%;
    background: linear-gradient(rgba(52, 58, 64, 1), rgba(0, 0, 0, 1)); 
}

#experience {
    min-height:100vh;
    padding-top: 130px;
    padding-bottom: 100px;
    background: linear-gradient(rgba(52, 58, 64, 1), rgba(0, 0, 0, 1)); 
}

.experience-title {
    font-size:40px;
    color:#fff;
}

.experience-subtitle {
    font-size:20px;
    color:#fff;
    font-weight:300;
}

#portfolio {
    min-height:100vh;
    padding-top: 130px;
    padding-bottom: 100px;
    background: linear-gradient(rgba(52, 58, 64, 1), rgba(0, 0, 0, 1)); 
}

#notfound {
    min-height:100vh;
    padding-top: 20%;
    margin: auto;
    display: block;
    background: linear-gradient(rgba(52, 58, 64, 1), rgba(0, 0, 0, 1)); 
}

.portfolio-title {
    font-size:40px;
    color:#fff;
}

.portfolio-subtitle {
    font-size:20px;
    color:#fff;
    font-weight:300;
}

.box-shadow {
    -webkit-box-shadow: 0px 10px 30px -22px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 30px -22px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 30px -22px rgba(0,0,0,0.75);
}

.banner-title {
    font-size:60px;
    color:#fff;
}

.font-primary {
    color: rgba(254, 92, 5, 0.8);
}

.banner-subtitle {
    font-size:25px;
    color:#fff;
    font-weight:300;
}

.banner-img {
    width:85%;
    margin-top:-70px;
    display:block;
    margin-left:auto;
    margin-right:auto;
}

.banner-img-skeleton {
    margin-left:auto;
    margin-right:auto;
    display: block;
}

.about-img {
    width:85%;
    display:block;
    margin-left:auto;
    margin-right:auto;
}

.about-img-skeleton {
    margin-left:auto;
    margin-right:auto;
    display: block;
}

#content-about {
    margin-top: 100px;
}

.btn-round{
    border-radius: 50%;
}
ul.sosmed {
    margin-left:-50px;
}
ul.sosmed li {
    display: inline;
    margin-left:10px;
}

/* section */

#services{
    padding-top:80px;
    padding-bottom:80px;
}
#about{
    padding-top:80px;
    padding-bottom:80px;
    background: linear-gradient( rgba(0, 0, 0, 1), rgba(52, 58, 64, 1)); 
}
#skills{
    padding-top:80px;
    padding-bottom:80px;
    background: linear-gradient(rgba(52, 58, 64, 1), rgba(0, 0, 0, 1)); 
}
#contact{
    padding-top:80px;
    padding-bottom:80px;
}

.progress {
    background-color: rgb(0,0,0,0);
    height: 20px;
}

/* text font */
.title {
    font-size:50px;
    color:#fff;
}

.color-white {
    color:#fff;
}

.subtitle {
    font-size:20px;
    color:#fff;
    font-weight:300;
}


.text-header {
    font-size:25px;
    color:#fff;
    font-weight: medium;
}

.text {
    font-size:20px;
    color:#fff;
    font-weight:300;
}

.card {
    padding:10px 10px;
    transition: transform 0.2s;
}

.card:hover{
    background-color: #fff;
    transform: scale(1.1);
}

a:hover{
    color:white;
}


a.dark-hover:hover{
    color:black;
}

.footer {
    padding:20px 20px;
    text-align: center;
}

.contact-us {
    text-decoration:none;
    font-size:25px;
}
/* animation */
.animation-fast {
    animation-duration: 0.5s;
}
.animation-medium {
    animation-duration: 1s;
}
.animation-slow {
    animation-duration: 1.5s;
}
.animation-delay-fast {
    animation-delay: 0.5s;
}
.animation-delay-medium {
    animation-delay: 1s;
}
.animation-delay-slow {
    animation-delay: 1.5s;
}

/* responsive view */
@media (max-width: 991px) {
    .banner-img {
        width:70%;
        margin-top: 50px;
        margin-bottom: 50px;
        /* display: none; */
    }

    .banner-img-skeleton {
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .about-img {
        width:70%;
        margin-bottom: 100px;
    }

    .about-img-skeleton {
        margin-bottom: 80px;
    }
    
    #content-about {
        margin-top: 40px;
    }

    #home{
        padding-top:80px;
        padding-bottom:100px;
    }

    .contact-us {
        font-size:15px;
    }

    .banner-title {
        font-size:30px;
    }

    .banner-subtitle {
        font-size:20px;
    }

    .experience-title {
        font-size:30px;
    }

    .experience-subtitle {
        font-size:18px;
    }

    .portfolio-title {
        font-size:30px;
    }

    .portfolio-subtitle {
        font-size:18px;
    }

    .title {
        font-size:30px;
    }

    .subtitle {
        font-size:18px;
        color:#fff;
    }

    .text-header {
        font-size:20px;
    }

    .text {
        font-size:18px;
    }

}
